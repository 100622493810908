import axios from "axios";

const API_URL = "https://ipc-server-fac46fdaae46.herokuapp.com/api/v1";

export const devInstance = axios.create({
    baseURL: API_URL,
});

devInstance.interceptors.response.use(
    async (config) => {
        config.headers = {
            'Content-Type': 'application/json',
            ...config.headers,
        };
        return config;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("token")
        }
        return Promise.reject(error);
    }
);
