import { useState, useEffect } from "react";
import Button from "./components/Button";
import FormInput from "./components/FormInput";
import Loader from "./components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { devInstance } from "./utils/devInstance";

function App() {
    const [contents, setContents] = useState([
        {
            contentTitle: "",
            contentSubtitle: "",
            contentImage: "",
        },
        {
            contentTitle: "",
            contentSubtitle: "",
            contentImage: "",
        },
        {
            contentTitle: "",
            contentSubtitle: "",
            contentImage: "",
        },
    ]);

    const [categories, setCategories] = useState([]);

    const [name, setName] = useState("");

    const [formData, setFormData] = useState({
        title: "",
        cover: "",
        content: "",
        headlines: [],
        conclusion: "",
        category: "",
        profile: {
            name: "Courage Michael",
            post: "Content writer",
            image: "https://res.cloudinary.com/ipcafrica/image/upload/v1688353616/courage_psqejs.png",
        },
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getCategories();
    }, []);

    const getCategories = () => {
        devInstance
            .get("/blog/category")
            .then((res) => {
                // setCategories(res.data.data.categories);
                setCategories(res.data.data.categories)
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error fetching resource");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addContent = () => {
        let newContent = {
            contentTitle: "",
            contentSubtitle: "",
            contentImage: "",
        };

        setContents([...contents, newContent]);
    };

    const deleteContent = (index) => {
        let arr = [...contents];
        let newArr = arr.filter((el, i) => index !== i);
        setContents(newArr);
    };

    console.log(categories, "sfdgsvef")

    const formChange = (e, index) => {
        if (e.target.name === "cover") {
            setLoading(true);
            const data = new FormData();
            data.append("file", e.target.files[0]);
            data.append("upload_preset", "ipc_images");
            fetch("https://api.cloudinary.com/v1_1/ipcafrica/image/upload", {
                method: "post",
                mode: "cors",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data.secure_url);
                    setFormData({
                        ...formData,
                        [e.target.name]: data.secure_url,
                    });
                    // console.log(formData);
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(`${err}`);
                })
                .finally(() => setLoading(false));
        } else {
            let comparison =
                e.target.name === "contentTitle" ||
                e.target.name === "contentSubtitle" ||
                e.target.name === "contentImage";
            if (comparison) {
                if (e.target.type === "file") {
                    setLoading(true);
                    const data = new FormData();
                    data.append("file", e.target.files[0]);
                    data.append("upload_preset", "assetmanagement");
                    fetch(
                        "https://api.cloudinary.com/v1_1/hammy06/image/upload",
                        {
                            method: "post",
                            mode: "cors",
                            body: data,
                        }
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data.secure_url);
                            let d = [...contents];
                            d[index][e.target.name] = e.target.value;
                            setContents(d);
                        })
                        .catch((err) => {
                            setLoading(false);
                            toast.error(`${err}`);
                        })
                        .finally(() => setLoading(false));
                } else {
                    let data = [...contents];
                    data[index][e.target.name] = e.target.value;
                    setContents(data);
                }
            } else {
                console.log("afcasdfcasd");
                if (e.target.type === "file") {
                    setLoading(true);
                    const data = new FormData();
                    data.append("file", e.target.files[0]);
                    data.append("upload_preset", "assetmanagement");
                    fetch(
                        "https://api.cloudinary.com/v1_1/hammy06/image/upload",
                        {
                            method: "post",
                            mode: "cors",
                            body: data,
                        }
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data.secure_url);
                            setFormData({
                                ...formData,
                                [e.target.name]: data.secure_url,
                            });
                            // console.log(formData);
                        })
                        .catch((err) => {
                            setLoading(false);
                            toast.error(`${err}`);
                        })
                        .finally(() => setLoading(false));
                } else {
                    setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                    });
                }
            }
        }

        console.log(formData);
    };

    const createCategory = (e) => {
        e.preventDefault();
        setLoading(true);
        devInstance
            .post("/blog/category", {
                name: name,
            })
            .then((res) => {
                console.log(res);
                toast.success("Category was successfully created");
                getCategories();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Category failed to create");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const postBlog = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = { ...formData, headlines: contents };
        console.log(data, "data");

        devInstance
            .post("/blog/posts", data)
            .then((res) => {
                console.log(res);
                toast.success("Post was successfully published");
            })
            .catch((err) => {
                console.log(err);
                toast.error("Post failed to publish");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changeForm = (e) => {
        setName(e.target.value);
        console.log(name);
    };

    return (
        <div className="App">
            <div className="w-screen min-h-screen py-20 px-5">
                <form
                    className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-20"
                    onSubmit={postBlog}
                >
                    <div className="flex flex-col gap-y-10">
                        <div className="">
                            <FormInput
                                type="select"
                                placeholder="Select a category"
                                options={categories}
                                label="Category"
                                name="category"
                                value={formData.category || null}
                                onChange={formChange}
                            />
                        </div>
                        <div className="flex flex-col gap-y-5">
                            <FormInput
                                type="file"
                                label="Add cover image"
                                name="cover"
                                onChange={formChange}
                            />
                            <FormInput
                                type="text"
                                placeholder="Enter Blog's Title"
                                label="Title"
                                name="title"
                                onChange={formChange}
                            />
                            <FormInput
                                type="rich-text"
                                placeholder="Blog's Subtitle"
                                label="Subtitle"
                                name="subtitle"
                                onChange={formChange}
                            />

                            <FormInput
                                type="rich-text"
                                placeholder="Subtitle"
                                label="After Image Subtitle"
                                name="content"
                                onChange={formChange}
                            />
                            <FormInput
                                type="rich-text"
                                placeholder="Conclusion"
                                label="Conclusion"
                                name="conclusion"
                                onChange={formChange}
                            />
                        </div>
                        <div className="hidden lg:block">
                            <Button buttonType="submit">Push Blog</Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-10">
                        <h1 className="text-4xl font-medium">Body Contents</h1>

                        <div className="flex flex-col gap-y-10">
                            {contents.map((el, index) => (
                                <div className="bg-gray-100 rounded-lg p-4 flex flex-col gap-y-3 relative">
                                    <span
                                        className="absolute right-4 top-4 cursor-pointer"
                                        onClick={() => deleteContent(index)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </span>

                                    <h4 className="text-lg">
                                        Content {index + 1}
                                    </h4>

                                    <div className="flex flex-col gap-y-3">
                                        <FormInput
                                            type="text"
                                            placeholder="Enter Blog's Title"
                                            label="Title"
                                            name="contentTitle"
                                            // value={el.contentTitle}
                                            onChange={(e) =>
                                                formChange(e, index)
                                            }
                                        />
                                        <FormInput
                                            type="rich-text"
                                            placeholder="Blog's Subtitle"
                                            label="Subtitle"
                                            name="contentSubtitle"
                                            // value={el.contentSubtitle}
                                            onChange={(e) =>
                                                formChange(e, index)
                                            }
                                        />
                                        <FormInput
                                            type="file"
                                            label="Add image (Optional)"
                                            name="contentImage"
                                            onChange={(e) =>
                                                formChange(e, index)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                variant="light"
                                width="half"
                                onClick={addContent}
                            >
                                Add more content
                            </Button>
                        </div>

                        <div className="lg:hidden block mt-10">
                            <Button buttonType="submit">Push Blog</Button>
                        </div>
                    </div>
                </form>

                <div className="container grid grid-cols-1 sm:grid-cols-2 mx-auto">
                    <form
                        className="container mx-auto flex flex-col gap-y-5 mt-10"
                        onSubmit={createCategory}
                    >
                        <h3 className="text-3xl">Create Category</h3>
                        <FormInput
                            type="text"
                            placeholder="Category Name"
                            label="Category Name"
                            name="name"
                            value={name}
                            onChange={changeForm}
                        />
                        <div className="mt-10">
                            <Button buttonType="submit">Push Category</Button>
                        </div>
                    </form>
                </div>
            </div>
            {loading && <Loader />}
            <ToastContainer />
        </div>
    );
}

export default App;
