import React from "react";

const Button = (props) => {
    const { variant, width, children, type, buttonType } = props;

    function widthProp() {
        return width === "half" ? "w-full sm:w-1/2" : "w-full";
    }

    function variantProp() {
        return variant === "light"
            ? "bg-white text-primary border border-primary/75 hover:bg-primary/5"
            : "bg-primary text-white hover:bg-primary/80";
    }

    function heightProp() {
        return type === "input" ? "h-10" : "h-12";
    }

    function buttonTypeProps() {
        return buttonType === "submit" ? "submit" : "button";
    }

    return (
        <button
            type={buttonTypeProps()}
            className={`${widthProp()} ${heightProp()} rounded-[100px] font-medium flex justify-center items-center gap-x-2 ${variantProp()}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
