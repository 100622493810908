import React, { useRef } from "react";
import Button from "./Button";

const FormInput = (props) => {
    const { label, type, options, placeholder } = props;
    const imgRef = useRef();
    return (
        <div className="flex flex-col gap-y-2 text-black/80">
            {label && <label>{label}</label>}
            {type === "select" ? (
                <select
                    className="h-14 rounded-xl border border-gray-400 px-4"
                    {...props}
                >
                    <option
                        className="text-gray-500 placeholder:text-gray-500"
                        selected
                        disabled
                    >
                        {placeholder}
                    </option>
                    {options?.map((el, index) => {
                        return (
                            <option value={el?.name} key={el?._id || index}>
                                {el?.name}
                            </option>
                        );
                    })}
                </select>
            ) : type === "file" ? (
                <>
                    <input
                        type="file"
                        className="hidden"
                        ref={imgRef}
                        {...props}
                    />
                    <div className="rounded-xl h-14 flex justify-between items-center border border-gray-400 px-4 bg-white gap-x-10 sm:gap-x-0">
                        <span>PNG/SVG/JPG</span>
                        <div className="w-[200px]">
                            <Button
                                variant="light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    imgRef.current.showPicker();
                                }}
                                type="input"
                            >
                                Upload Image
                            </Button>
                        </div>
                    </div>
                </>
            ) : type === "rich-text" ? (
                <textarea
                    className="h-[145px] rounded-xl border border-gray-400 px-4 py-2"
                    {...props}
                ></textarea>
            ) : (
                <input
                    className="h-14 rounded-xl border border-gray-400 px-4"
                    {...props}
                />
            )}
        </div>
    );
};

export default FormInput;
